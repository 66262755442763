
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { usePayoutCodeDealer } from "@/hooks/payment/mobile/usePayoutCodeDealer";
import {
  toThousandsAndTwoDecimal,
  toThousands,
} from "@/utils/payment/numberFormat";
import MButton from "../Components/MButton.vue";
import { HomeOutlined } from "@ant-design/icons-vue";
import MInfoModal from "../Components/MInfoModal.vue";
import MToTop from "@/views/Payment/Mobile/Components/MToTop.vue";
import ApprovalModal from "./approvalModal.vue";
import MSegment from "../Components/MSegment.vue";
import MTitle from "../Components/MTitle.vue";
import ApproveSteps from "@/views/Payment/Mobile/Components/ApproveSteps.vue";
export default defineComponent({
  setup() {
    const {
      total,
      data,
      programYear,
      actionType,
      approvalModalVisible,
      modalVisible,
      modalMessage,
      handleCommentsConfirm,
      toHomePage,
      approveList,
    } = usePayoutCodeDealer();
    const handleReject = () => {
      actionType.value = "reject";
      approvalModalVisible.value = true;
    };
    const handleApprove = () => {
      actionType.value = "approve";
      approvalModalVisible.value = true;
    };

    // 吸顶
    const isFixed = ref<boolean>(false);
    let offsetTop = 0;
    const fixedRef = ref<null | HTMLElement>(null);
    const pageRef = ref<null | HTMLElement>(null);
    const toTopShow = ref<boolean>(false);
    const initHeight = () => {
      const scrollTop = pageRef.value ? pageRef.value?.scrollTop : 0;
      isFixed.value = scrollTop > offsetTop;
      toTopShow.value = scrollTop > 0;
    };
    onMounted(() => {
      offsetTop = fixedRef.value ? fixedRef.value?.offsetTop : offsetTop;
      pageRef.value?.addEventListener("scroll", initHeight);
    });
    onUnmounted(() => {
      pageRef.value?.removeEventListener("scroll", initHeight);
    });
    return {
      total,
      data,
      programYear,
      toThousandsAndTwoDecimal,
      toThousands,
      handleReject,
      handleApprove,
      actionType,
      approvalModalVisible,
      modalVisible,
      modalMessage,
      handleCommentsConfirm,
      toHomePage,
      isFixed,
      fixedRef,
      pageRef,
      toTopShow,
      approveList,
    };
  },
  components: {
    MButton,
    HomeOutlined,
    MToTop,
    ApprovalModal,
    MInfoModal,
    MSegment,
    MTitle,
    ApproveSteps,
  },
});
