import { ref } from "vue";
import { PayoutCodeOverviewData } from "@/views/Payment/Mobile/PendingApproval/type";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import * as API from "@/API/payment";
import { ApproveData } from "@/views/Payment/types";
import { message } from 'ant-design-vue';

export const usePayoutCodeDealer = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const data = ref<PayoutCodeOverviewData>({
    id: 0,
    payoutRound: "",
    payoutCode: "",
    programNo: 0,
    dealerNo: 0,
    payoutAmountWVat: "",
    payoutAmountWVoat: "",
    programYear: null,
  });
  const programYear = ref<any[]>([]);

  let userName = store.state.user.fulnm;
  if (userName.indexOf("Sales") != -1) {
    userName = "OEM IT Admin";
  }
  API.getDealerDetail(route.query.id as string).then((res) => {
    data.value = res;
    // data.value.payoutAmountWVat = route.query.wvat as string;
    // data.value.payoutAmountWVoat = route.query.wvoat as string;
    data.value.payoutAmountWVat = res.amountWVat
    data.value.payoutAmountWVoat = res.amountWOVat
  });

  API.getPendingDetailD(route.query.id).then((res) => {
    const programYearObj = {};
    for (let i = 0; i < res.length; i++) {
      if (!programYearObj[res[i].year]) {
        // programYearObj[res[i].year] = 0;
        programYearObj[res[i].year] = res[i].payoutAmountWOVat;
      }
      // if (res[i].payoutAmountWOVat) {
      //   programYearObj[res[i].year] += parseFloat(res[i].payoutAmountWOVat);
      // }
    }
    for (const year in programYearObj) {
      programYear.value.push({ year, payoutAmountWOVat: programYearObj[year] });
    }
    programYear.value.sort((a, b) => a.year - b.year);
  });

  const total = ref<string>("0");
  if (route.query.total) {
    total.value = route.query.total + "";
  } else {
    const params = {
      pageNumber: 1,
      pageSize: 10000,
      userId: store.state.user.usrid,
      typeList: [],
    };
    API.getDealerPendingList(params).then((res) => {
      total.value = res.content.length;
      API.getFinancePendingList(params).then((res) => {
        total.value += res.content.length;
      });
    });
  }

  const actionType = ref<string>("approve");
  const approvalModalVisible = ref<boolean>(false);
  const modalVisible = ref<boolean>(false);
  const modalMessage = ref<string>("");
  const showMessage = (message: string) => {
    modalMessage.value = message;
    modalVisible.value = true;
  };
  const toHomePage = () => {
    router.push({ path: "/paymentMobile/pendingApproval" });
  };
  const handleCommentsConfirm = (comment: string) => {
    const closeModal = () => {
      approvalModalVisible.value = false;
    };
    const params = [{
      rejectComment: '',
      approveComment: "",
      delegationUserId: route.query.delegationUserId,
      id: data.value?.id,
      requestUserId: route.query.requestUserId,
    }]
    if (actionType.value === "reject") {
      params[0].rejectComment = comment;
      API.fetchPaymentReject(params).then((res) => {
        if (res.code == "0") {
          closeModal();
          showMessage("Successfully Reject！");
          setTimeout(() => {
            toHomePage();
          }, 2000);
        } else {
          closeModal();
          showMessage(res.message);
        }
      });
    } else if (actionType.value === "approve") {
      params[0].approveComment = comment;
      API.fetchPaymentApprove(params).then((res) => {
        if (res.code == "0") {
          closeModal();
          showMessage("Successfully Approve！");
          setTimeout(() => {
            toHomePage();
          }, 2000);
        } else {
          closeModal();
          showMessage(res.message);
        }
      });
    }
  };
  // 审批相关
  const approveList = ref<ApproveData[]>();
  approveList.value = [];
  const id = route.query.id as string;
  const getApprovalPath = () => {
    const params = {
      approvalType: "PayoutApproval",
      payoutCodeId: id,
      payoutType: "DEALER",
    };
    API.getApprovalPackageByCreated(params).then((res: any) => {
      if (res.code === "0") {
        approveList.value = res.data.map((item: any) => item.nodeList)
      } else {
        message.error(res.message)
      }
    });
  };
  getApprovalPath()
  return {
    total,
    data,
    programYear,
    actionType,
    approvalModalVisible,
    modalVisible,
    modalMessage,
    handleCommentsConfirm,
    toHomePage,
    approveList,
  };
};
